import { useWoody } from '@/src/services/woody/woody';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';
import { useMutation } from '@tanstack/react-query';
import { AnalyticsEvents } from '../../analytics/analytics.types';
import { toast } from '@/src/store/alerts';

const useMutationMagicRename = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  return useMutation({
    mutationFn: async (resourceId: string) => {
      const response = await client.v2('/v2/suggest-name', {
        method: 'post',
        body: {
          resourceId,
        },
      });

      if (!response) throw new Error('Not enough data.');

      return response;
    },
    onSuccess: (_, resourceId) => {
      track(AnalyticsEvents.MagicRename, {
        resourceId,
      });
    },
    onError: () => {
      toast({
        id: 'magic-rename-fail',
        content: 'Not enough information to suggest a new name.',
      });
    },
  });
};

export { useMutationMagicRename };
