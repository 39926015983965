import { animate, AnimatePresence, motion, useMotionValue, useWillChange } from 'framer-motion';
import { CSSProperties, useState } from 'react';
import styled, { css } from 'styled-components';
import { FloatAnchor } from '../../ui/components/FloatAnchor';
import { cssVar } from '../../ui/theme/variables';
import { size } from '@floating-ui/react';

interface AnimatedTextOptions {
  updateRealText: (text: string) => void;
  floatingTextStyle?: CSSProperties;
  overflows?: boolean;
}

interface FloatingTextProps {
  overflows?: boolean;
}
const FloatingText = styled(motion.div)<FloatingTextProps>`
  z-index: 127;
  display: inline-block;

  > span {
    display: inline-flex;
    transform-origin: center;
    letter-spacing: -0.001em;
  }

  color: ${cssVar['color-text-primary']};
  font-weight: 500;

  ${({ overflows }) =>
    overflows &&
    css`
      white-space: pre-wrap;
      word-break: break-all;
      line-break: anywhere;
      overflow-wrap: break-word;
    `}
`;

const useAnimatedText = (options: AnimatedTextOptions) => {
  const { updateRealText, floatingTextStyle, overflows } = options;
  const willChange = useWillChange();
  const animatedPosition = useMotionValue(0);
  const [animatedText, setAnimatedText] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);

  const onAnimateText = (newText: string) => {
    animatedPosition.set(0);
    setAnimatedText('');

    animate(animatedPosition, newText.length, {
      duration: newText.length / 50,
      onPlay() {
        setIsAnimating(true);
      },
      onUpdate(latest) {
        const updateText = newText.substring(0, Math.round(latest));
        setAnimatedText(updateText);
        updateRealText(updateText);
      },
      onComplete() {
        setIsAnimating(false);
        updateRealText(newText);
      },
    });
  };

  return {
    isAnimating,
    onAnimateText,
    inputStyle: isAnimating
      ? {
          color: '#00000000',
        }
      : {},
    addAnimation: (children: React.ReactNode, disabled = false) =>
      disabled ? (
        children
      ) : (
        <FloatAnchor.Root
          // since we just want it on top of the current element there is no need to use transform
          // and using transform causes blurry text as it shows up
          useTransform={false}
          offset={({ rects }) => {
            if (rects.floating.width - 15 > rects.reference.width) setIsAnimating(false);

            return overflows
              ? -rects.reference.height
              : -rects.reference.height / 2 - rects.floating.height / 2;
          }}
          middleware={
            overflows
              ? [
                  size({
                    apply({ rects, elements }) {
                      Object.assign(elements.floating.style, {
                        maxWidth: `${rects.reference.width}px`,
                        height: `${rects.reference.height}px`,
                      });
                    },
                  }),
                ]
              : undefined
          }
          open={isAnimating}
        >
          <FloatAnchor.Trigger action="manual" asChild>
            {children}
          </FloatAnchor.Trigger>
          <FloatAnchor.Floating asChild>
            <FloatingText style={floatingTextStyle} overflows={overflows}>
              <AnimatePresence>
                {animatedText.split('').map((c, i) => (
                  <motion.span
                    key={i}
                    initial={{
                      transform: 'translateY(7px)',
                      opacity: 0,
                      filter: 'blur(2px)',
                    }}
                    animate={{
                      transform: 'translateY(0)',
                      opacity: 1,
                      filter: 'blur(0)',
                      // After the transition ends we revert back to inline otherwise
                      // it will have a slightly different text size which feels abrupt when
                      // handing it back to the input.
                      transitionEnd: {
                        display: 'inline',
                      },
                    }}
                    style={{
                      willChange,
                    }}
                  >
                    {c === ' ' ? '\u00A0' : c}
                  </motion.span>
                ))}
              </AnimatePresence>
            </FloatingText>
          </FloatAnchor.Floating>
        </FloatAnchor.Root>
      ),
  };
};

export { useAnimatedText };
