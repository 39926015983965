import { useScrollPosition } from '@/src/hooks/useScrollPosition';
import { Flex } from '@/src/modules/ui/components/Flex';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { mediaMobile, mediaTablet } from '@/src/modules/ui/styled-utils';
import { CssBackgroundProp, getCssBackground } from '@/src/modules/ui/theme/cssBackground';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';

interface DashboardContentEmptyContainerProps extends CssBackgroundProp {
  children: React.ReactNode;
}

const dashboardContentPadding = {
  desktop: '3.125rem',
  tablet: '1.875rem',
  mobile: '1rem',
};

interface ContentInnerProps {
  maxContentSize?: 'medium' | 'xs';
  disablePadding?: boolean;
  disableSidePadding?: boolean;
  bottomPaddingOffset?: string;
}
const ContentInner = styled(motion(Flex))
  .withConfig(preventForwardPropsConfig(['maxContentSize', 'disablePadding', 'disableSidePadding']))
  .attrs<ContentInnerProps>((props) => {
    return {
      direction: 'column',
      gap: 'sections',
      alignItems: 'stretch',
      ...props,
    };
  })`

  margin-left: auto;
  margin-right: auto;
  min-height: 100%;

  padding: ${dashboardContentPadding.desktop};
  padding-bottom: calc(
    var(--safe-margin-offset-bottom) + ${dashboardContentPadding.desktop} + ${(p) => p.bottomPaddingOffset || '5rem'}
  );

  ${mediaTablet} {
    padding: ${dashboardContentPadding.tablet};
    padding-bottom: calc(
      var(--safe-margin-offset-bottom) + ${dashboardContentPadding.tablet} + ${(p) => p.bottomPaddingOffset || '5rem'}
    );
  }
  ${mediaMobile} {
    padding: ${dashboardContentPadding.mobile};
    // +1 assistant button
    padding-bottom: calc(
      var(--safe-margin-offset-bottom) + ${dashboardContentPadding.mobile} + ${(p) => p.bottomPaddingOffset || '5rem'}
    );
    width: 100dvw;
  }

  ${(p) => {
    switch (p.maxContentSize) {
      case 'medium':
        return css`
          max-width: calc(1248px + ${dashboardContentPadding.desktop} * 2);
          ${mediaTablet} {
            max-width: calc(1248px + ${dashboardContentPadding.tablet} * 2);
          }

          ${mediaMobile} {
            max-width: calc(1248px + ${dashboardContentPadding.mobile} * 2);
          }
        `;
      case 'xs':
        return css`
          max-width: calc(500px + ${dashboardContentPadding.desktop} * 2);

          ${mediaTablet} {
            max-width: calc(500px + ${dashboardContentPadding.tablet} * 2);
          }

          ${mediaMobile} {
            max-width: calc(500px + ${dashboardContentPadding.mobile} * 2);
          }
        `;
    }
  }}
  ${(p) =>
    p.disablePadding &&
    css`
      padding: 0 0 var(--safe-margin-offset-bottom) 0 !important;
    `}
  ${(p) =>
    p.disableSidePadding &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}
`;

export interface DashboardContentProps extends CssBackgroundProp {
  expandVertically?: boolean;
  maxHeightAndCenterContent?: boolean;
  maxContentSize?: 'medium';
  innerProps?: React.ComponentPropsWithoutRef<typeof ContentInner> & {
    ['data-multiselect-allow']?: boolean;
  };
}

/**
 * use this component inside DashboardContent with disabled side padding
 */
export const DashboardContentSection = styled.div`
  padding: 0 ${dashboardContentPadding.desktop};

  ${mediaTablet} {
    padding: 0 ${dashboardContentPadding.tablet};
  }
  ${mediaMobile} {
    padding: 0 ${dashboardContentPadding.mobile};
  }
`;

export const DashboardContent = styled(ScrollArea.Root)
  .attrs<DashboardContentProps>(
    ({ maxHeightAndCenterContent, innerProps, expandVertically = true, ...props }) => {
      const viewportRef = React.useRef<HTMLDivElement | null>(null);
      const [scrollPosition] = useScrollPosition(viewportRef.current);

      return {
        grow: true,
        ...props,
        orientation: 'vertical',
        'data-scroll-vertical': scrollPosition?.vertical,
        'data-scroll-horizontal': scrollPosition?.horizontal,
        children: (
          <>
            <ScrollArea.Viewport
              ref={viewportRef}
              style={maxHeightAndCenterContent ? { height: 'auto' } : undefined}
              expandVertically={expandVertically}
            >
              <ContentInner {...innerProps}>{props.children}</ContentInner>
            </ScrollArea.Viewport>
            <ScrollArea.Bar orientation="vertical">
              <ScrollArea.Thumb />
            </ScrollArea.Bar>
            <ScrollArea.Corner />
          </>
        ),
      };
    },
  )
  .withConfig(
    preventForwardPropsConfig([
      'maxHeightAndCenterContent',
      'maxContentSize',
      'innerProps',
      'background',
      'viewportRef',
    ]),
  )`
  &>[data-radix-scroll-area-viewport]>div {
    display: block !important;
  }

  &>[data-radix-scroll-area-viewport] > div {
    // https://github.com/radix-ui/primitives/issues/926
    display: block !important;
  }

  ${getCssBackground('secondary')}
  ${(p) =>
    p.maxHeightAndCenterContent &&
    css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
    `}
`;

/**
 * use inside DashboardContent
 */

const EmptyContainerInner = styled(Flex).attrs((props) => {
  return {
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 'sections',
    ...props,
  };
})`
  max-width: 560px;
  text-align: center;
`;
export const DashboardContentEmptyContainer = styled(motion(Flex))
  .attrs<DashboardContentEmptyContainerProps>((props) => {
    return {
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      ...props,
      children: <EmptyContainerInner>{props.children}</EmptyContainerInner>,
    };
  })
  .withConfig(preventForwardPropsConfig(['background']))`
  min-height: 70vh;
  ${getCssBackground('transparent')}
`;
