import React, { createContext, useContext } from 'react';
import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  OffsetOptions,
  UseFloatingOptions,
} from '@floating-ui/react';
import { Slot } from '@radix-ui/react-slot';
import * as Portal from '@radix-ui/react-portal';
import { AnimatePresence } from 'framer-motion';
import { useBoolState } from '@/src/hooks/useBooleanState';

const FloatAnchorContext = createContext<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refs: {
    setReference: (node: HTMLElement | null) => void;
    setFloating: (node: HTMLElement | null) => void;
  };
  floatingStyles: React.CSSProperties;
} | null>(null);

interface FloatAnchorRootProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  children: React.ReactNode;
  offset?: OffsetOptions;
  middleware?: UseFloatingOptions['middleware'];
  useTransform?: boolean;
}

const FloatAnchorRoot: React.FC<FloatAnchorRootProps> = ({
  open,
  setOpen,
  children,
  offset: offsetOptions = 5,
  middleware,
  useTransform = true,
}) => {
  const defaultOpenState = useBoolState();

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [offset(offsetOptions), flip(), shift(), ...(middleware ?? [])],
    whileElementsMounted: autoUpdate,
    transform: useTransform,
  });

  return (
    <FloatAnchorContext.Provider
      value={{
        open: open ?? defaultOpenState.value,
        setOpen: setOpen ?? defaultOpenState.set,
        refs,
        floatingStyles,
      }}
    >
      {children}
    </FloatAnchorContext.Provider>
  );
};

interface FloatAnchorTriggerProps {
  children: React.ReactNode;
  asChild?: boolean;
  action?: 'hover' | 'focus' | 'click' | 'manual';
}

const FloatAnchorTrigger: React.FC<FloatAnchorTriggerProps> = ({
  children,
  action = 'hover',
  asChild,
}) => {
  const context = useContext(FloatAnchorContext);
  if (!context) throw new Error('FloatAnchorTrigger must be used within FloatAnchorRoot');

  const { setOpen } = context;

  const Component = asChild ? Slot : 'div';

  return (
    <Component
      ref={context.refs.setReference}
      onMouseEnter={() => action === 'hover' && setOpen(true)}
      onMouseLeave={() => action === 'hover' && setOpen(false)}
      onFocus={() => action === 'focus' && setOpen(true)}
      onBlur={() => action === 'focus' && setOpen(false)}
      onClick={() => action === 'click' && setOpen(!open)}
    >
      {children}
    </Component>
  );
};

interface FloatAnchorFloatingProps {
  children: React.ReactNode;
  asChild?: boolean;
}

const FloatAnchorFloating: React.FC<FloatAnchorFloatingProps> = ({ children, asChild }) => {
  const context = useContext(FloatAnchorContext);
  if (!context) throw new Error('FloatAnchorFloating must be used within FloatAnchorRoot');

  const Component = asChild ? Slot : 'div';

  return (
    <Portal.Root>
      <Portal.Portal>
        <AnimatePresence>
          {context.open && (
            <Component
              ref={context.refs.setFloating}
              style={context.floatingStyles}
              data-state={context.open ? 'open' : 'closed'}
              key="floating"
            >
              {children}
            </Component>
          )}
        </AnimatePresence>
      </Portal.Portal>
    </Portal.Root>
  );
};

const FloatAnchor = {
  Root: FloatAnchorRoot,
  Trigger: FloatAnchorTrigger,
  Floating: FloatAnchorFloating,
};

export { FloatAnchor };
