import * as React from 'react';
import ShockIcon from '@/public/images/icons/Shock.svg';
import { FloatAnchor } from '../../ui/components/FloatAnchor';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { cssVar } from '../../ui/theme/variables';
import { Button } from '../../ui/components/Button';
import { useBoolState } from '@/src/hooks/useBooleanState';
import { trimChars } from '@/src/utils/text';
import { useMutationMagicRename } from '../mutations/useMutationMagicRename';

const StyledButton = styled(motion(Button)).attrs((p) => {
  return {
    size: 'sm',
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    variant: 'bg-primary',
    ...p,
  };
})`
  pointer-events: auto;

  color: ${cssVar['color-app-primary-text']};
  padding: 0.5rem;
  z-index: 127;

  transition:
    0.2s outline,
    0.2s box-shadow;
`;

interface MagicRenameProps {
  children: React.ReactNode;
  resourceId?: string;
  onRename?: (newName: string) => void;

  onFetch?: () => void;
  onSettled?: () => void;
}

const MagicRename: React.FC<MagicRenameProps> = ({
  children,
  resourceId,
  onRename,
  onFetch,
  onSettled,
}) => {
  const { mutate: mutateMagicRename, isPending, isError } = useMutationMagicRename();

  const openState = useBoolState();

  const requestNewName = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (!resourceId) return;
    e.preventDefault();
    e.stopPropagation();

    onFetch?.();
    mutateMagicRename(resourceId, {
      onSuccess: (name) => {
        onRename?.(trimChars(name, ['"', "'"]));
      },
      onSettled: onSettled,
    });
  };

  return (
    <FloatAnchor.Root
      open={isPending || !resourceId || isError ? false : openState.value}
      setOpen={openState.set}
    >
      <FloatAnchor.Trigger action="focus" asChild>
        {children}
      </FloatAnchor.Trigger>
      <FloatAnchor.Floating asChild>
        <StyledButton onPointerDown={(e) => e.preventDefault()} onPointerUp={requestNewName}>
          <ShockIcon />
          Name this for me
        </StyledButton>
      </FloatAnchor.Floating>
    </FloatAnchor.Root>
  );
};

export { MagicRename };
